<template>
  <div class="view">
    <div class="box">
      <span style="font-size: 13px;">正在验证...</span>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {user} from '@/api'

export default {
  name: "wx",
  data() {
    return {
      code: '',
      href: ''
    }
  },
  created() {
    let arr = location.href.split('/');
    this.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + encodeURI(window.location.href) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechart_redirect';
    this.code = this.$route.query.code;
    if (!this.code) {
      window.location.replace(this.href);
    } else {
      this.validateUser();
    }
  },
  methods: {
    ...mapMutations(['SET_WX_INFO', 'SET_USERINFO', 'SET_MEMBER', 'SET_TOKEN']),
    async validateUser() {
      //获取微信用户信息
      try {
        const {
          data,
          code,
          msg
        } = await user.loginByJsCode(this.code, localStorage.shareUserId || '', localStorage.sharePlanId || '');

        if (code != 1) {
          return this.$router.replace('/home');
        }

        this.SET_TOKEN(data.token);
        this.SET_USERINFO(data);
        setTimeout(() => {
          this.$store.dispatch('getWallet');
        }, 1000)
        const res = await user.memberInfo();
        if (!res.data) {
          this.SET_MEMBER({vipIdentify: 0});
        } else {
          this.SET_MEMBER(res.data);
        }

        const redirect = this.$route.query.redirect
        if (!data.mobile) {
          // this.$router.replace('/login/bindTel')
          this.$store.commit('SET_LOGIN_REDIRECT',redirect||'/home')
          this.$store.state.isLoginSetMobile = true;
          this.$store.dispatch('showLoginDialog');
          this.$store.dispatch('')
        } else {
          if (this.$route.query.redirect) {
            console.log(this.$route.query.redirect);
            return window.location.href = this.$route.query.redirect;
          }
          this.$router.replace('/home');
        }


      } catch (e) {
        this.$router.replace('/home');
      }


    }
  }
}
</script>

<style lang="less" scoped>
.view {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    width: 100px;
    height: 100px;
    background: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    span {
      font-size: 13px;
      color: #fff;
    }
  }
}
</style>
